/* Dark theme for ReactQuill */
.ql-toolbar {
    background-color: #1f2937; /* Dark background */
    border: 1px solid #374151; /* Dark border */
  }
  
  .ql-container {
    background-color: #1f2937; /* Dark background for the editor */
    color: white; /* White text for the content */
    border: 1px solid #374151; /* Border to match the toolbar */
  }
  
  .ql-toolbar .ql-picker-label, 
  .ql-toolbar .ql-picker-item {
    color: white; /* White text for toolbar icons */
  }
  
  .ql-toolbar button {
    color: white; /* White toolbar buttons */
  }
  
  .ql-editor {
    color: white; /* White text inside the editor */
  }
  
  .ql-snow .ql-stroke {
    stroke: white; /* Toolbar icons stroke color in white */
  }
  
  .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
    fill: white; /* Toolbar icons fill color in white */
  }
  
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background-color: #1f2937; /* Dark background for dropdowns */
    border-color: #374151;
  }
  
  .ql-snow .ql-picker-options .ql-picker-item {
    color: white; /* White text in dropdown items */
  }
  