
.custom-text-color {
    color: hsl(28, 100%, 60%);
  }
  .custom-bg-color {
    background-color: hsl(28, 100%, 60%);
}
.hover\:custom-text-color:hover {
  color: hsl(28, 100%, 60%);
}

.about-container {
  width: 100%; /* Set container width to full */
}

.slider-container {
  max-width: 1600px; /* Adjust the max-width as needed */
  width: 100%;
  margin: 0 auto;
}
.custom-hr {
  border: 1px solid white; /* Style the horizontal line */
  width: 100%; /* Full width */
  margin-bottom: 2rem; /* Space below the line */
}
/* Ensure the arrow is visible */
/* Ensure the arrow and text are positioned correctly */
.slider-container .arrow, .slider-container .hover-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* Start invisible */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
  z-index: 10; /* Ensure it appears on top of other elements */
  color: white; /* Ensure the arrow and text are a contrasting color */
}

.slider-container .hover-text {
  top: 70%; /* Position text below the arrow */
  font-size: 1rem;
}

.slider-container .arrow {
  top: 50%; /* Center the arrow vertically */
  font-size: 2rem;
}

/* Make the arrow and text visible on hover, and apply blur */
.slider-container .motion-div:hover .arrow,
.slider-container .motion-div:hover .hover-text {
  opacity: 1; /* Make visible on hover */
}

.slider-container .motion-div:hover img {
  filter: blur(4px); /* Apply blur effect */
}

.slider-container .motion-div {
  position: relative;
}

/* Position the arrow and text over the image */
.image-container {
  position: relative;
  overflow: hidden; /* Ensure the content stays within the container */
}

.image-container img {
  transition: filter 0.3s ease-in-out;
}

/* Ensure the arrow and text are positioned within the image container */
.image-container .arrow, .image-container .hover-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* Start invisible */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
  color: white; /* Ensure the arrow and text are a contrasting color */
  z-index: 10; /* Ensure they appear above the image */
}

.image-container .arrow {
  top: 40%; /* Center the arrow vertically in the image */
  font-size: 2rem;
}

.image-container .hover-text {
  top: 60%; /* Position text below the arrow in the image */
  font-size: 1rem;
}

/* Make the arrow and text visible on hover, and apply blur to the image */
.image-container:hover .arrow,
.image-container:hover .hover-text {
  opacity: 1; /* Make visible on hover */
}

.image-container:hover img {
  filter: blur(4px); /* Apply blur effect to the image */
}

