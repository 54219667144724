/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  background-color: #007bff;
  padding: 10px;
  color: #fff;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  /* background-color: #0f0e0e !important; */
  font-family: "Centra", sans-serif !important;
}

a:hover {
  text-decoration: none;
}

/* img {
  width: 100%;
  height: auto;
} */
/* 
button {
  border: 0;
  background-color: transparent;
} */

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* Styles for laptop view (screen width 768px and above) */
@media (min-width: 768px) {
  .App {
    width: 100%;
    margin: 0 auto;
  }
}

/* Styles for mobile view (screen width up to 767px) */
@media (max-width: 620px) {
  .App {
    width: 100%;
    /* padding: 10px; */
    /* display: flex; */
    position: absolute;
  }
}

