.marquee {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.marquee-content {
    display: inline-flex;
    animation: scroll 20s linear infinite;
}
.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
}

.grid-item-placeholder {
    background-color: #444;
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* Maintain aspect ratio */
    position: relative;
}

.grid-item-placeholder::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
.mosaic-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 150px;
    gap: 10px;
}

.mosaic-item {
    background-color: #444;
    position: relative;
    overflow: hidden;
}

/* Different sizes for mosaic effect */
.mosaic-item-1 {
    grid-column: span 2;
    grid-row: span 2;
}

.mosaic-item-2 {
    grid-column: span 1;
    grid-row: span 1;
}

.mosaic-item-3 {
    grid-column: span 1;
    grid-row: span 2;
}

.mosaic-item-4 {
    grid-column: span 2;
    grid-row: span 1;
}

.mosaic-item-5 {
    grid-column: span 1;
    grid-row: span 1;
}

.mosaic-item-6 {
    grid-column: span 2;
    grid-row: span 2;
}

.mosaic-item-7 {
    grid-column: span 1;
    grid-row: span 1;
}

.mosaic-item-8 {
    grid-column: span 1;
    grid-row: span 2;
}

.mosaic-item-9 {
    grid-column: span 2;
    grid-row: span 1;
}

.mosaic-item-10 {
    grid-column: span 1;
    grid-row: span 1;
}
