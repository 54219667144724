/* Hide scrollbar */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  /* Ensure list items have no default list styling */
  li.list-none {
    list-style: none;
  }
  
  /* Adjust the positioning of the navigation buttons and tabs */
  .relative.mb-4 {
    position: relative;
  }
  
  .relative.mb-4 button {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .flex.space-x-4.overflow-x-auto.scrollbar-hide.mx-12 {
    padding: 0 50px; /* Create space for the navigation buttons */
  }
  
  button.left-0, button.right-0 {
    top: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  